<template>
  <div>
    <Assistant_teacher_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-account-star"
        title="ข้อมูลครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2" outlined>
          <v-row no-gutters>
            <v-col cols="12" md="12" class="text-right pa-2">
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <div class="text-right">
                    <v-btn
                      depressed
                      color="primary"
                      @click.native="manage_assistant_teacherCSV()"
                    >
                      <v-icon>mdi-file-import</v-icon>นำเข้าข้อมูล
                    </v-btn>

                    <v-btn
                      outlined
                      color="info"
                      @click.native="manage_assistant_teacherSearchQueryAll()"
                      class="mr-2"
                    >
                      <v-icon>mdi-magnify</v-icon>ค้นหาข้อมูลครูผู้ช่วยซ้ำซ้อน
                    </v-btn>

                    <v-btn
                      outlined
                      color="info"
                      @click.native="manage_assistant_teacherQueryAll()"
                    >
                      <v-icon>mdi-clipboard-text</v-icon>แสดงข้อมูลทั้งหมด
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="manage_assistant_teachers"
          :search="search"
          group-by="mt_id_branch"
          class="elevation-1"
          :item-class="row_classes"
          disable-pagination
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง หรือ รหัสบัตรประชาชน + Enter"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
                @keyup="manage_assistant_teacheridSeach"
              />
            </div>
            <div>
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                label="เลือกคอลัมน์ที่ต้องการแสดง"
                multiple
                outlined
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 6">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 6" class="grey--text caption"
                    >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
                  >
                </template>
              </v-select>
            </div>
          </template>
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="34">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].mt_id_branch + " : " + items[0].name_branch }}
            </th>
          </template>

          <template v-slot:item.mt_status="{ item }">
            <v-chip dark color="red" v-if="item.mt_status === 'disclaim'">
              <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
              สละสิทธิ์
            </v-chip>
            <v-chip dark color="green" v-else>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_examtype="{ item }">
            <v-chip dark color="purple" v-if="item.mt_examtype === 'select'">
              คัดเลือก
            </v-chip>
            <v-chip dark color="indigo" v-else>
              ทั่วไป
            </v-chip>
          </template>

          <template v-slot:item.mt_examgroup="{ item }">
            <div class="text-center">
              <v-chip
                dark
                color="purple"
                v-if="item.mt_examgroup === 'general'"
              >
                ทั่วไป
              </v-chip>
              <v-chip dark color="indigo" v-else>
                จชต.
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'transfer_position'"
              >
                ครูโอนย้าย
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'local_dev'"
              >
                ครูพัฒนาท้องถิ่น
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_admissions_status="{ item }">
            <v-icon
              v-if="item.mt_admissions_status === 'receipt'"
              color="green"
            >
              mdi-email-open
            </v-icon>
            <v-icon
              v-else-if="item.mt_admissions_status === 'missing'"
              color="red"
            >
              mdi-information
            </v-icon>

            <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
          </template>

          <template v-slot:item.mt_status_report="{ item }">
            <div class="text-center">
              <div v-if="item.mt_status_report === 'confirm'">
                <v-icon size="30" color="warning">mdi-send-check</v-icon>
              </div>

              <div v-else-if="item.mt_status_report === 'approve'">
                <v-icon size="30" color="success">mdi-check-circle</v-icon>
              </div>
              <div v-else>
                <v-icon size="30" color="error">mdi-alert</v-icon>
              </div>
            </div>
          </template>

          <template v-slot:item.action_check="{ item }">
            <div v-if="item.mt_status_report === 'confirm'">
              <v-btn
                @click="checkData(item.mt_id)"
                fab
                small
                outlined
                color="warning"
                size="40"
                ><v-icon>mdi-circle-outline</v-icon></v-btn
              >
            </div>

            <div v-else-if="item.mt_status_report === 'approve'">
              <v-btn
                @click="checkData(item.mt_id)"
                fab
                small
                outlined
                color="success"
                size="40"
                ><v-icon>mdi-check-circle</v-icon></v-btn
              >
            </div>
          </template>

          <template v-slot:item.mt_license_status="{ item }">
            <div class="text-center">
              <div v-if="item.mt_license_status === 'have'">
                <v-icon size="30" color="success">mdi-clipboard-check</v-icon>
              </div>
              <div v-else>
                <v-icon size="30" color="error">mdi-close-box</v-icon>
              </div>
            </div>
          </template>

          <template v-slot:item.pt_licence_file="{ item }">
            <div class="text-center">
              <div v-if="String(item.pt_licence_file).length > 5">
                <v-btn
                  @click="pdflicenseteachdialogOpen(item.pt_licence_file)"
                  fab
                  small
                  outlined
                  color="info"
                >
                  <v-icon size="30" color="success"
                    >mdi-card-account-details</v-icon
                  >
                </v-btn>
              </div>
              <div v-else></div>
            </div>
          </template>

          <template v-slot:item.pt_ondate="{ item }">
            <div>
              {{
                item.pt_ondate
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.pt_enddate="{ item }">
            <div>
              {{
                item.pt_enddate
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.mt_transcript="{ item }">
            <div class="text-center">
              <div v-if="String(item.mt_transcript).length > 5">
                <v-btn
                  @click="pdfmt_transcriptdialogOpen(item.mt_transcript)"
                  fab
                  small
                  outlined
                  color="info"
                >
                  <v-icon size="30" color="success">mdi-text-box-search</v-icon>
                </v-btn>
              </div>
              <div v-else></div>
            </div>
          </template>

          <template v-slot:item.mt_idcard_copy="{ item }">
            <div class="text-center">
              <div v-if="String(item.mt_idcard_copy).length > 5">
                <v-btn
                  @click="pdfmt_idcard_copydialogOpen(item.mt_idcard_copy)"
                  fab
                  small
                  outlined
                  color="info"
                >
                  <v-icon size="30" color="success">mdi-text-box-search</v-icon>
                </v-btn>
              </div>
              <div v-else></div>
            </div>
          </template>

          <template v-slot:item.mt_change_name="{ item }">
            <div class="text-center">
              <div v-if="String(item.mt_change_name).length > 5">
                <v-btn
                  @click="pdfmt_change_namedialogOpen(item.mt_change_name)"
                  fab
                  small
                  outlined
                  color="info"
                >
                  <v-icon size="30" color="success">mdi-text-box-search</v-icon>
                </v-btn>
              </div>
              <div v-else></div>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="warning"
              @click.stop="manage_assistant_teacherEdit(item.mt_id)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              @click.stop="manage_assistant_teacherDelete(item.mt_id)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model deletemanage_assistant_teacherdialog -->
      <v-layout>
        <v-dialog
          v-model="deletemanage_assistant_teacherdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบรายการข้อมูลครูผู้ช่วย รายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form
                    ref="deletemanage_assistant_teacherform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{
                            editmanage_assistant_teacher.mt_id_card +
                              " : " +
                              editmanage_assistant_teacher.mt_title_s +
                              editmanage_assistant_teacher.mt_frist_name +
                              " : " +
                              editmanage_assistant_teacher.mt_last_name
                          }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="deletemanage_assistant_teacherdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletemanage_assistant_teacherSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editmanage_assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editmanage_assistant_teacherdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลครูผู้ช่วย รายงานตัว"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editmanage_assistant_teacherform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="เลขบัตรประชาชน"
                        v-model="editmanage_assistant_teacher.mt_id_card"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-autocomplete
                        :items="branchs"
                        item-text="name_branch"
                        item-value="id_branch"
                        outlined
                        label="สาขาวิชา"
                        v-model="editmanage_assistant_teacher.mt_id_branch"
                        required
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.id_branch }} :
                              {{ item.name_branch }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ลำดับที่สอบได้"
                        v-model="editmanage_assistant_teacher.mt_sequence"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="คำนำหน้าชื่อ"
                        v-model="editmanage_assistant_teacher.mt_title_s"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ชื่อ"
                        v-model="editmanage_assistant_teacher.mt_frist_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="นามสกุล"
                        v-model="editmanage_assistant_teacher.mt_last_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เบอร์โทร"
                        v-model="editmanage_assistant_teacher.mt_tel_p"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ชื่อย่อวุฒิการศึกษา"
                        v-model="editmanage_assistant_teacher.mt_ed_abb"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="สาขาวิชาเอก"
                        v-model="editmanage_assistant_teacher.mt_ed_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="คำสั่งที่"
                        v-model="editmanage_assistant_teacher.mt_order_app"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_status_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="สถานะ"
                        v-model="editmanage_assistant_teacher.mt_status"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_examtype_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="วิธีการ"
                        v-model="editmanage_assistant_teacher.mt_examtype"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_examgroup_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="เขตพื้นที่"
                        v-model="editmanage_assistant_teacher.mt_examgroup"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="type_personnelApp"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="ประเภทการบรรจุ"
                        v-model="editmanage_assistant_teacher.mt_type_personnel"
                        required
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        v-model="editmanage_assistant_teacher.mt_date_app_now"
                        label="วันที่บรรจุ"
                        prepend-icon="mdi-calendar"
                        outlined
                        type="date"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        v-model="
                          editmanage_assistant_teacher.mt_date_app_postitionnow
                        "
                        label="วันที่ดำรงตำแหน่ง"
                        prepend-icon="mdi-calendar"
                        outlined
                        type="date"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-radio-group
                        v-model="
                          editmanage_assistant_teacher.mt_admissions_status
                        "
                        row
                      >
                        <template v-slot:label>
                          <div>
                            สถานะการรายงานตัว ณ สถานศึกษา
                            <strong> ปรับปรุง</strong>
                          </div>
                        </template>
                        <v-radio value="send">
                          <template v-slot:label>
                            <div>
                              <strong class="warning--text"
                                >ส่งข้อมูลไปยังสถานศึกษา</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="receipt">
                          <template v-slot:label>
                            <div>
                              <strong class="success--text"
                                >รับรายงานตัว</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="missing">
                          <template v-slot:label>
                            <div>
                              <strong class="error--text"
                                >สละสิทธิ์ไม่รายงานตัว</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                outlined
                @click.stop="editmanage_assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editmanage_assistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdflicenseteachdialog_2 -->
      <v-dialog v-model="pdflicenseteachdialog_2" max-width="80%">
        <v-card class="" elevation="2">
          <!-- {{ pt_licence_files }} -->
          <embed
            :src="'/HRvecLicenseTeach/' + pt_licence_files"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdftranscriptdialog -->
      <v-dialog v-model="pdftranscriptdialog_2" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HREducation/' + mt_transcript"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdfidcarddialog -->
      <v-dialog v-model="pdfidcarddialog_2" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRIDcard/' + mt_idcard_copy"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdfidcardChangedialog -->
      <v-dialog v-model="pdfidcardChangedialog_2" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRIDcard/' + mt_change_name"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model checkDataDialog -->
      <v-dialog v-model="checkDataDialog" max-width="80%">
        <v-card class="" elevation="2">
          <v-row>
            <v-col cols="12" md="12">
              <div>
                <v-card max-width="80%" class="mx-auto" outlined>
                  <div class="mt-5 pa-5">
                    <v-img
                      v-if="
                        String(manage_assistant_teacherid.mt_picprofile)
                          .length > 10
                      "
                      src="https://hrvec.ovec.go.th/empty.png"
                      height="300px"
                      contain
                      dark
                    >
                    </v-img>

                    <v-img
                      v-else
                      src="https://hrvec.ovec.go.th/empty.png"
                      height="300px"
                      contain
                      dark
                    ></v-img>
                    <div
                      class="text-center"
                      v-if="
                        String(manage_assistant_teacherid.mt_picprofile)
                          .length > 10
                      "
                    >
                      <v-btn
                        @click="deletePic()"
                        class="mr-1 mt-1"
                        color="error"
                        outlined
                      >
                        <v-icon>mdi-delete</v-icon> ลบ</v-btn
                      >
                    </div>

                    <div
                      class="mt-2"
                      v-if="
                        String(manage_assistant_teacherid.mt_picprofile)
                          .length < 10
                      "
                    >
                      <v-alert prominent type="error" outlined>
                        <v-row align="center">
                          <v-col class="grow">
                            <h3 class="pa-2">
                              รูปถ่าย 1 นิ้ว ไฟล์ JPG.
                            </h3>
                            <v-file-input
                              v-model="mt_picprofile"
                              accept="image/่jpg, image/jpeg"
                              name="mt_picprofile"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์รูปภาพ .jpg/jpeg"
                              placeholder="Select your files"
                              prepend-icon="mdi-paperclip"
                              outlined
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </v-col>
                          <v-col class="shrink text-center">
                            <v-btn
                              @click="UpdateProfile()"
                              class=""
                              color="success"
                              outlined
                            >
                              <v-icon>mdi-content-save</v-icon> บันทึก</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-alert>
                    </div>
                  </div>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-tie
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title class="font14">
                              {{
                                manage_assistant_teacherid.mt_title_s +
                                  manage_assistant_teacherid.mt_frist_name +
                                  " " +
                                  manage_assistant_teacherid.mt_last_name
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{
                              manage_assistant_teacherid.mt_id_card
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action></v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              manage_assistant_teacherid.mt_brith_day +
                                "-" +
                                manage_assistant_teacherid.mt_brith_month +
                                "-" +
                                manage_assistant_teacherid.mt_brith_year
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >วัน-เดือน-ปี เกิด</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-phone
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title class="font14">
                              <v-text-field
                                label="เบอร์โทรติดต่อ"
                                v-model="manage_assistant_teacherid.mt_tel_p"
                                outlined
                              ></v-text-field>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-cog
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              >{{ manage_assistant_teacherid.mt_id_branch }} :
                              {{
                                manage_assistant_teacherid.name_branch
                              }}</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >สาขาวิชา</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action></v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title
                              >{{ manage_assistant_teacherid.mt_ed_abb }}
                              {{
                                manage_assistant_teacherid.mt_ed_name
                              }}</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >วุฒิการศึกษา</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <div class="text-center">
                                <v-btn
                                  @click="UpdateProfile()"
                                  color="success"
                                  outlined
                                >
                                  <v-icon>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                              </div>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>

                    <v-col cols="12" md="12">
                      <hr />
                    </v-col>

                    <v-col cols="12" md="12">
                      <div class="text-center font14">
                        <v-radio-group
                          class="ml-15"
                          v-model="manage_assistant_teacherid.mt_license_status"
                          row
                        >
                          <template v-slot:label>
                            <div class="font14">
                              สถานะ ใบอนุญาตประกอบวิชาชีพครู
                              <strong> ระบุ</strong>
                            </div>
                          </template>
                          <v-radio value="have">
                            <template v-slot:label>
                              <div class="font14">
                                <strong class="success--text"
                                  >มีใบประกอบวิชาชีพครู</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="donot">
                            <template v-slot:label>
                              <div class="font14">
                                <strong class="warning--text"
                                  >ไม่มีใบประกอบวิชาชีพ</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      v-if="
                        manage_assistant_teacherid.mt_license_status === 'have'
                      "
                    >
                      <div class="pa-2">
                        <div
                          v-if="
                            String(manage_assistant_teacherid.pt_licence_file)
                              .length < 10
                          "
                        >
                          <v-file-input
                            v-model="pt_licence_file"
                            accept=".pdf"
                            name="pt_licence_file"
                            color="deep-purple accent-4"
                            counter
                            label="ใบอนุญาตประกอบวิชาชีพครู .pdf"
                            placeholder="Select your files"
                            prepend-icon="mdi-paperclip"
                            outlined
                            :rules="rules"
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>

                          <div class="text-center mb-5">
                            <v-btn
                              @click="UpdateProfile()"
                              color="success"
                              outlined
                            >
                              <v-icon>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                          </div>
                        </div>

                        <div
                          class="text-center mb-5"
                          v-if="
                            String(manage_assistant_teacherid.pt_licence_file)
                              .length > 10
                          "
                        >
                          <v-btn
                            outlined
                            color="info"
                            @click="viewpt_licence_file()"
                          >
                            <v-icon color="info" size="40"
                              >mdi-file-pdf-box</v-icon
                            >
                            ใบอนุญาตประกอบวิชาชีพครู
                          </v-btn>

                          <v-btn
                            @click="deleteLicense()"
                            color="error"
                            class="ml-2"
                            outlined
                          >
                            <v-icon>mdi-delete</v-icon> ลบ
                          </v-btn>
                        </div>

                        <div>
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th width="60%" class="text-center">
                                  รายการข้อมูล
                                </th>
                                <th class="text-center">
                                  สถานะ
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="pa-2">
                                  <v-text-field
                                    prepend-icon="mdi-card-account-details"
                                    outlined
                                    label="เลขที่ใบอนุญาตประกอบวิชาชีพครู"
                                    v-model="
                                      manage_assistant_teacherid.pt_licence
                                    "
                                  ></v-text-field>
                                </td>
                                <td class="text-center">
                                  <div
                                    v-if="
                                      String(
                                        manage_assistant_teacherid.pt_licence
                                      ).length > 5
                                    "
                                  >
                                    เลขที่ใบอนุญาตประกอบวิชาชีพครู <br />

                                    {{ manage_assistant_teacherid.pt_licence }}
                                  </div>
                                  <div v-else>
                                    <v-icon size="40" color="error"
                                      >mdi-alert</v-icon
                                    ><br />
                                    กรุณากรอกข้อมูลให้ถูกต้อง
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td class="pa-2">
                                  <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="
                                          manage_assistant_teacherid.pt_ondate
                                        "
                                        label="วันที่ออกบัตร"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="
                                        manage_assistant_teacherid.pt_ondate
                                      "
                                      no-title
                                      scrollable
                                      locale="th"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(date)"
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </td>
                                <td class="text-center">
                                  <div
                                    v-if="
                                      String(
                                        manage_assistant_teacherid.pt_ondate
                                      ).length > 5
                                    "
                                  >
                                    วันที่ออกบัตร <br />

                                    {{
                                      manage_assistant_teacherid.pt_ondate
                                        | moment("add", "543 year")
                                        | moment("D MMMM YYYY")
                                    }}
                                  </div>
                                  <div v-else>
                                    <v-icon size="40" color="error"
                                      >mdi-alert</v-icon
                                    ><br />
                                    กรุณากรอกข้อมูลให้ถูกต้อง
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="pa-2">
                                  <v-menu
                                    ref="menu1"
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="
                                          manage_assistant_teacherid.pt_enddate
                                        "
                                        label="วันที่บัตรหมดอายุ"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="
                                        manage_assistant_teacherid.pt_enddate
                                      "
                                      no-title
                                      scrollable
                                      locale="th"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="menu1 = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu1.save(date)"
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </td>
                                <td class="text-center">
                                  <div
                                    v-if="
                                      String(
                                        manage_assistant_teacherid.pt_enddate
                                      ).length > 5
                                    "
                                  >
                                    วันที่บัตรหมดอายุ <br />

                                    {{
                                      manage_assistant_teacherid.pt_enddate
                                        | moment("add", "543 year")
                                        | moment("D MMMM YYYY")
                                    }}
                                  </div>
                                  <div v-else>
                                    <v-icon size="40" color="error"
                                      >mdi-alert</v-icon
                                    ><br />
                                    กรุณากรอกข้อมูลให้ถูกต้อง
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>

                        <div class="text-center">
                          <v-btn
                            @click="UpdateProfile()"
                            color="success"
                            outlined
                          >
                            <v-icon>mdi-content-save</v-icon> บันทึก
                          </v-btn>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12" md="12">
                      <div class="pa-2">
                        <v-simple-table>
                          <thead>
                            <tr>
                              <th width="70%" class="text-center">
                                File
                              </th>
                              <th class="text-center">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="pa-5">
                                  <h3 class="pa-2">
                                    สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ
                                  </h3>
                                  <div
                                    v-if="
                                      String(
                                        manage_assistant_teacherid.mt_ed_file
                                      ).length < 5
                                    "
                                  >
                                    <v-file-input
                                      v-model="mt_ed_file"
                                      accept=".pdf"
                                      name="mt_ed_file"
                                      color="deep-purple accent-4"
                                      counter
                                      label="สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ .pdf"
                                      placeholder="Select your files"
                                      prepend-icon="mdi-paperclip"
                                      outlined
                                      :rules="rules"
                                      :show-size="1000"
                                    >
                                      <template
                                        v-slot:selection="{ index, text }"
                                      >
                                        <v-chip
                                          v-if="index < 2"
                                          color="deep-purple accent-4"
                                          dark
                                          label
                                          small
                                        >
                                          {{ text }}
                                        </v-chip>

                                        <span
                                          v-else-if="index === 2"
                                          class="text-overline grey--text text--darken-3 mx-2"
                                        >
                                          +{{ files.length - 2 }} File(s)
                                        </span>
                                      </template>
                                    </v-file-input>
                                  </div>

                                  <div v-else class="text-center">
                                    <v-btn
                                      outlined
                                      color="info"
                                      @click="viewed_file_file()"
                                    >
                                      <v-icon color="info" size="40"
                                        >mdi-file-pdf-box</v-icon
                                      >
                                      สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ
                                    </v-btn>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  class="text-center"
                                  v-if="
                                    String(
                                      manage_assistant_teacherid.mt_ed_file
                                    ).length < 5
                                  "
                                >
                                  <v-btn
                                    @click="UpdateProfile()"
                                    color="success"
                                    outlined
                                  >
                                    <v-icon>mdi-content-save</v-icon> บันทึก
                                  </v-btn>
                                </div>
                                <div v-else class="text-center">
                                  <v-btn
                                    @click="deleteed_file()"
                                    color="error"
                                    class="ml-2"
                                    outlined
                                  >
                                    <v-icon>mdi-delete</v-icon> ลบ
                                  </v-btn>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div class="pa-5">
                                  <h3 class="pa-2">
                                    สำเนาใบรายงานผลการเรียน (Transcript)
                                  </h3>
                                  <div
                                    v-if="
                                      String(
                                        manage_assistant_teacherid.mt_transcript
                                      ).length < 5
                                    "
                                  >
                                    <v-file-input
                                      v-model="mt_transcript"
                                      accept=".pdf"
                                      name="mt_transcript"
                                      color="deep-purple accent-4"
                                      counter
                                      label="สำเนาใบรายงานผลการเรียน (Transcript) .pdf"
                                      placeholder="Select your files"
                                      prepend-icon="mdi-paperclip"
                                      outlined
                                      :rules="rules"
                                      :show-size="1000"
                                    >
                                      <template
                                        v-slot:selection="{ index, text }"
                                      >
                                        <v-chip
                                          v-if="index < 2"
                                          color="deep-purple accent-4"
                                          dark
                                          label
                                          small
                                        >
                                          {{ text }}
                                        </v-chip>

                                        <span
                                          v-else-if="index === 2"
                                          class="text-overline grey--text text--darken-3 mx-2"
                                        >
                                          +{{ files.length - 2 }} File(s)
                                        </span>
                                      </template>
                                    </v-file-input>
                                  </div>

                                  <div v-else class="text-center">
                                    <v-btn
                                      outlined
                                      color="info"
                                      @click="viewmt_transcriptfile()"
                                    >
                                      <v-icon color="info" size="40"
                                        >mdi-file-pdf-box</v-icon
                                      >
                                      สำเนาใบรายงานผลการเรียน (Transcript)
                                    </v-btn>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  class="text-center"
                                  v-if="
                                    String(
                                      manage_assistant_teacherid.mt_transcript
                                    ).length < 5
                                  "
                                >
                                  <v-btn
                                    @click="UpdateProfile()"
                                    color="success"
                                    outlined
                                  >
                                    <v-icon>mdi-content-save</v-icon> บันทึก
                                  </v-btn>
                                </div>
                                <div v-else class="text-center">
                                  <v-btn
                                    @click="deletmt_transcript()"
                                    color="error"
                                    class="ml-2"
                                    outlined
                                  >
                                    <v-icon>mdi-delete</v-icon> ลบ
                                  </v-btn>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div class="pa-5">
                                  <h3 class="pa-2">
                                    สำเนาบัตรประจำตัวประชาชน
                                  </h3>
                                  <div
                                    v-if="
                                      String(
                                        manage_assistant_teacherid.mt_idcard_copy
                                      ).length < 5
                                    "
                                  >
                                    <v-file-input
                                      v-model="mt_idcard_copy"
                                      accept=".pdf"
                                      name="mt_idcard_copy"
                                      color="deep-purple accent-4"
                                      counter
                                      label="สำเนาบัตรประจำตัวประชาชน .pdf"
                                      placeholder="Select your files"
                                      prepend-icon="mdi-paperclip"
                                      outlined
                                      :rules="rules"
                                      :show-size="1000"
                                    >
                                      <template
                                        v-slot:selection="{ index, text }"
                                      >
                                        <v-chip
                                          v-if="index < 2"
                                          color="deep-purple accent-4"
                                          dark
                                          label
                                          small
                                        >
                                          {{ text }}
                                        </v-chip>

                                        <span
                                          v-else-if="index === 2"
                                          class="text-overline grey--text text--darken-3 mx-2"
                                        >
                                          +{{ files.length - 2 }} File(s)
                                        </span>
                                      </template>
                                    </v-file-input>
                                  </div>

                                  <div v-else class="text-center">
                                    <v-btn
                                      outlined
                                      color="info"
                                      @click="viewmt_idcard_copyfile()"
                                    >
                                      <v-icon color="info" size="40"
                                        >mdi-file-pdf-box</v-icon
                                      >
                                      สำเนาบัตรประจำตัวประชาชน
                                    </v-btn>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  class="text-center"
                                  v-if="
                                    String(
                                      manage_assistant_teacherid.mt_idcard_copy
                                    ).length < 5
                                  "
                                >
                                  <v-btn
                                    @click="UpdateProfile()"
                                    color="success"
                                    outlined
                                  >
                                    <v-icon>mdi-content-save</v-icon> บันทึก
                                  </v-btn>
                                </div>
                                <div v-else class="text-center">
                                  <v-btn
                                    @click="deletmt_idcard_copy()"
                                    color="error"
                                    class="ml-2"
                                    outlined
                                  >
                                    <v-icon>mdi-delete</v-icon> ลบ
                                  </v-btn>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div class="pa-5">
                                  <h3 class="pa-2">
                                    สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุล
                                    หรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี)
                                  </h3>
                                  <div
                                    v-if="
                                      String(
                                        manage_assistant_teacherid.mt_change_name
                                      ).length < 5
                                    "
                                  >
                                    <v-file-input
                                      v-model="mt_change_name"
                                      accept=".pdf"
                                      name="mt_change_name"
                                      color="deep-purple accent-4"
                                      counter
                                      label="สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุล หรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี) .pdf"
                                      placeholder="Select your files"
                                      prepend-icon="mdi-paperclip"
                                      outlined
                                      :rules="rules"
                                      :show-size="1000"
                                    >
                                      <template
                                        v-slot:selection="{ index, text }"
                                      >
                                        <v-chip
                                          v-if="index < 2"
                                          color="deep-purple accent-4"
                                          dark
                                          label
                                          small
                                        >
                                          {{ text }}
                                        </v-chip>

                                        <span
                                          v-else-if="index === 2"
                                          class="text-overline grey--text text--darken-3 mx-2"
                                        >
                                          +{{ files.length - 2 }} File(s)
                                        </span>
                                      </template>
                                    </v-file-input>
                                  </div>

                                  <div v-else class="text-center">
                                    <v-btn
                                      outlined
                                      color="info"
                                      @click="viewmt_change_namefile()"
                                    >
                                      <v-icon color="info" size="40"
                                        >mdi-file-pdf-box</v-icon
                                      >
                                      สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุล
                                      หรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี)
                                    </v-btn>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  class="text-center"
                                  v-if="
                                    String(
                                      manage_assistant_teacherid.mt_change_name
                                    ).length < 5
                                  "
                                >
                                  <v-btn
                                    @click="UpdateProfile()"
                                    color="success"
                                    outlined
                                  >
                                    <v-icon>mdi-content-save</v-icon> บันทึก
                                  </v-btn>
                                </div>
                                <div v-else class="text-center">
                                  <v-btn
                                    @click="deletmt_change_name()"
                                    color="error"
                                    class="ml-2"
                                    outlined
                                  >
                                    <v-icon>mdi-delete</v-icon> ลบ
                                  </v-btn>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>

                      <div class="text-center">
                        <v-btn
                          @click="UpdateProfileConfirm()"
                          large
                          color="success"
                        >
                          <v-icon>mdi-content-save</v-icon> ยืนยันรายการ
                          (บันทึกข้อมูล)</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <!-- V-model pdflicenseteachdialog -->
      <v-dialog v-model="pdflicenseteachdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="
              '/HRvecLicenseTeach/' + manage_assistant_teacherid.pt_licence_file
            "
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdfeducationdialog -->
      <v-dialog v-model="pdfeducationdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HREducation/' + manage_assistant_teacherid.mt_ed_file"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdftranscriptdialog -->
      <v-dialog v-model="pdftranscriptdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HREducation/' + manage_assistant_teacherid.mt_transcript"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdfidcarddialog -->
      <v-dialog v-model="pdfidcarddialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRIDcard/' + manage_assistant_teacherid.mt_idcard_copy"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdfidcardChangedialog -->
      <v-dialog v-model="pdfidcardChangedialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRIDcard/' + manage_assistant_teacherid.mt_change_name"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model confirmCheckdialog -->
      <v-dialog v-model="confirmCheckdialog" max-width="60%">
        <v-card class="pa-2" elevation="2">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <div>
                <v-alert
                  border="top"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  ตรวจสอบ หากพบข้อมูลพลาด กรุณาให้เหตุผลการยกเลิก
                </v-alert>
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <v-radio-group
                v-model="manage_assistant_teacherid.mt_status_report"
                row
              >
                <template v-slot:label>
                  <div><strong>การตรรวจสอบ : </strong></div>
                </template>
                <v-radio value="approve">
                  <template v-slot:label>
                    <div>
                      <strong class="success--text">คุณสมบัติถูกต้อง</strong>
                    </div>
                  </template>
                </v-radio>
                <v-radio value="cc">
                  <template v-slot:label>
                    <div>
                      <strong class="warning--text">ยกเลิกรายการ</strong>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>

            <v-col
              cols="12"
              md="12"
              v-if="manage_assistant_teacherid.mt_status_report === 'cc'"
            >
              <v-text-field
                prepend-icon="mdi-card-account-details"
                outlined
                label="ให้เหตุผลประกอบการ ยกเลิกรายการ"
                v-model="manage_assistant_teacherid.mt_status_comment"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <div class="text-center">
                <v-btn @click="UpdateCheckConfirm()" color="success" outlined>
                  <v-icon>mdi-content-save</v-icon> บันทึกรายการ</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <!-- V-model manage_assistant_teacherCSVdialog -->
      <v-layout>
        <v-dialog
          v-model="manage_assistant_teacherCSVdialog"          
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลครูผู้ช่วย ใบอนุญาตสอน"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form
                    ref="manage_assistant_teacherCSVdialogform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            outlined
                            block
                            large
                            color="info"
                            to="/admin/manage_assistant_teacher_importidteach/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV ครูผู้ช่วย"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                              :rules="[v => !!v || '']"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <v-btn
                            class="pa-2"
                            block
                            large
                            color="green"
                            @click="csvManpowerSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="manage_assistant_teacherCSVdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Assistant_teacher_bar from "../../components/admin/assistant_teacher_bar.vue";

export default {
  name: "HRvecManageAssistantTeacher",
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      file2: null,
      addmanage_assistant_teacherdialog: false,
      editmanage_assistant_teacherdialog: false,
      deletemanage_assistant_teacherdialog: false,

      pdflicenseteachdialog: false,
      pdflicenseteachdialog_2: false,
      pdftranscriptdialog: false,
      pdfidcarddialog: false,
      pdfidcardChangedialog: false,
      checkDataDialog: false,
      confirmCheckdialog: false,
      pdftranscriptdialog_2: false,
      pdfidcarddialog_2: false,
      pdfidcardChangedialog_2: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_assistant_teachers: [],
      addmanage_assistant_teacher: {},
      editmanage_assistant_teacher: {},
      colleges: [],
      userstatus: [],
      search: "",
      pagination: {},
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "วิธีการ", align: "left", value: "mt_examtype" },
        { text: "ประเภทกลุ่ม", align: "left", value: "mt_examgroup" },
        { text: "สถานะ", align: "center", value: "mt_status" },
        /*   {
                    text: "การรายงานตัว",
                    align: "center",
                    value: "mt_admissions_status"
                }, */
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        },
        { text: "รอบที่", align: "left", value: "mt_times" },
        { text: "ปี", align: "left", value: "mt_years" },
        { text: "รหัสบัตร", align: "left", value: "mt_id_card" },
        { text: "สาขาวิชา", align: "left", value: "mt_id_branch" },
        { text: "ลำดับที่", align: "left", value: "mt_sequence" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "นามสกุล", align: "left", value: "mt_last_name" },

        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "เลขที่ตำแหน่ง", align: "left", value: "mt_id_position" },

        { text: "วันที่บรรจุ", align: "left", value: "mt_appoin_day" },
        { text: "เดือนบรรจุ", align: "left", value: "mt_appoin_month" },
        { text: "ปีบรรจุ", align: "left", value: "mt_appoin_year" },
        { text: "คำสั่ง", align: "left", value: "mt_order_app" },
        { text: "สถานะรายงาน", align: "center", value: "mt_status_report" },

        { text: "ตรวจสอบ", align: "center", value: "action_check" },

        { text: "สถานะใบประกอบ", align: "center", value: "mt_license_status" },
        { text: "ใบประกอบ", align: "center", value: "pt_licence_file" },
        { text: "เลขที่", align: "left", value: "pt_licence" },
        { text: "วันออก", align: "center", value: "pt_ondate" },
        { text: "วันหมด", align: "center", value: "pt_enddate" },
        { text: "Transcript", align: "center", value: "mt_transcript" },
        { text: "IDcard", align: "center", value: "mt_idcard_copy" },
        { text: "การเปลี่ยนชื่อ", align: "center", value: "mt_change_name" },

        { text: "วันเกิด", align: "left", value: "mt_brith_day" },
        { text: "เดือนเกิด", align: "left", value: "mt_brith_month" },
        { text: "ปีเกิด", align: "left", value: "mt_brith_year" },
        { text: "โทร", align: "left", value: "mt_tel_p" },
        { text: "วุฒิการศึกษา", align: "left", value: "mt_ed_abb" },
        { text: "เอกวิชา", align: "left", value: "mt_ed_name" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      collgegs: [],
      manage_assistant_teacherstatus: [],
      regions: [],
      region_ena: true,
      data_syslog: {},
      branchs: [],
      periodassteachs: [],
      headersCount: [
        { text: "รอบที่", align: "left", value: "mt_times" },
        { text: "ปี", align: "left", value: "mt_years" },
        { text: "รหัส", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "branchName" },
        { text: "จำนวน", align: "left", value: "branchGroup" },
        { text: "แสดง", align: "left", value: "actions" }
      ],
      manage_assistant_teacherCount: [],
      mt_status_select: [
        { text: "ปกติ", value: "normal" },
        { text: "สละสิทธิ์", value: "disclaim" }
      ],
      mt_examtype_select: [
        { text: "คัดเลือก(ภายใน)", value: "select" },
        { text: "ทั่วไป(ภายนอก)", value: "recruit" }
      ],
      mt_examgroup_select: [
        { text: "ทั่วไป", value: "general" },
        { text: "ชายแดนภาคใต้", value: "southern" }
      ],
      type_personnelApp: [
        { text: "ครูพัฒนาท้องถิ่น", value: "local_dev" },
        { text: "ครูโอนย้าย", value: "transfer_position" }
      ],
      updatemanage_assistant_teachers: {},
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu1: false,
      menu2: false,
      pt_licence_files: "",
      mt_transcript: [],
      mt_idcard_copy: [],
      mt_change_name: [],
      manage_assistant_teacherid: [],
      mt_picprofile: null,
      mt_picprofile: null,
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB"
      ],
      pdfeducationdialog: false,
      pdftranscriptdialog: false,
      pdfidcarddialog: false,
      pdfidcardChangedialog: false,
      massege_cc: "",
      manage_assistant_teacherCSVdialog: false
    };
  },

  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    await this.branchQuery();
  },

  methods: {
    async csvManpowerSubmit() {
      if (this.$refs.manage_assistant_teacherCSVdialogform.validate()) {
        let result = "";
        if (this.file2 != null) {
          let formData = new FormData();
          let filename = this.time_stamp + ".manpower.csv";
          formData.append("file", this.file2);
          formData.append("filename", "../HRvecfiles/" + filename);
          formData.append("ApiKey", this.ApiKey);
          result = await this.$http.post(
            "manage_assistant_teacherCSVidTeach.php",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )        
        } 
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.manage_assistant_teacherCSVdialog = false;
      }
    },

    async manage_assistant_teacherCSV() {
      this.manage_assistant_teacherCSVdialog = true;
    },

    async manage_assistant_teacheridSeach(e) {
      if (e.keyCode == 13) {
        this.loading = true;
        let result = await this.$http
          .post("manage_assistant_teacher.php", {
            ApiKey: this.ApiKey,
            mt_id_card: this.search,
            report_selete: "ok"
          })
          .finally(() => (this.loading = false));
        this.manage_assistant_teachers = result.data;
      }
    },

    async deleteLicense() {
      Swal.fire({
        title: "ยืนยันการลบ ใบอนุญาตประกอบวิชาชีพครู",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacherid.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecLicenseTeach/" +
              this.manage_assistant_teacherid.pt_licence_file
          });

          this.manage_assistant_teacherid.pt_licence_file = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacherid
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.manage_assistant_teacherQueryAll();
        }
      });
    },
    async deletePic() {
      Swal.fire({
        title: "ยืนยันการลบ รูปถ่าย",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacherid.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" + this.manage_assistant_teacherid.mt_picprofile
          });

          this.manage_assistant_teacherid.mt_picprofile = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacherid
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.manage_assistant_teacherQueryAll();
        }
      });
    },

    async deleteed_file() {
      Swal.fire({
        title: "ยืนยันการลบ สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacherid.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HREducation/" + this.manage_assistant_teacherid.mt_ed_file
          });

          this.manage_assistant_teacherid.mt_ed_file = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacherid
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.manage_assistant_teacherQueryAll();
        }
      });
    },

    async deletmt_transcript() {
      Swal.fire({
        title: "ยืนยันการลบ สำเนาใบรายงานผลการเรียน (Transcript)",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacherid.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HREducation/" + this.manage_assistant_teacherid.mt_transcript
          });

          this.manage_assistant_teacherid.mt_transcript = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacherid
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.manage_assistant_teacherQueryAll();
        }
      });
    },

    async deletmt_idcard_copy() {
      Swal.fire({
        title: "ยืนยันการลบ สำเนาบัตรประจำตัวประชาชน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacherid.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRIDcard/" + this.manage_assistant_teacherid.mt_idcard_copy
          });

          this.manage_assistant_teacherid.mt_idcard_copy = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacherid
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.manage_assistant_teacherQueryAll();
        }
      });
    },

    async deletmt_change_name() {
      Swal.fire({
        title:
          "ยืนยันการลบ สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุลหรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี)",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacherid.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRIDcard/" + this.manage_assistant_teacherid.mt_change_name
          });

          this.manage_assistant_teacherid.mt_change_name = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacherid
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.manage_assistant_teacherQueryAll();
        }
      });
    },

    async UpdateProfileConfirm() {
      this.confirmCheckdialog = true;
    },

    async UpdateCheckConfirm() {
      this.manage_assistant_teacherid.ApiKey = this.ApiKey;
      let result_up = await this.$http.post(
        "manage_assistant_teacher.update.php",
        this.manage_assistant_teacherid
      );
      if (result_up.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.manage_assistant_teacherQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.confirmCheckdialog = false;
      this.checkDataDialog = false;
    },

    async viewpt_licence_file() {
      this.pdflicenseteachdialog = true;
    },

    async viewed_file_file() {
      this.pdfeducationdialog = true;
    },

    async viewmt_transcriptfile() {
      this.pdftranscriptdialog = true;
    },

    async viewmt_idcard_copyfile() {
      this.pdfidcarddialog = true;
    },

    async viewmt_change_namefile() {
      this.pdfidcardChangedialog = true;
    },

    async UpdateProfile() {
      this.manage_assistant_teacherid.ApiKey = this.ApiKey;
      let uploaded = null;
      let result_pic = "";
      if (this.mt_picprofile != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacherid.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "users.jpg";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_picprofile);
        formData.append("filename", "../HRvecfiles/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacherid.mt_picprofile = "";
        this.manage_assistant_teacherid.mt_picprofile = filename;
        uploaded = true;
      }

      if (this.pt_licence_file != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacherid.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "teach.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.pt_licence_file);
        formData.append("filename", "../HRvecLicenseTeach/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacherid.pt_licence_file = "";
        this.manage_assistant_teacherid.pt_licence_file = filename;
        uploaded = true;
      }

      if (this.mt_ed_file != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacherid.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "ed.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_ed_file);
        formData.append("filename", "../HREducation/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacherid.mt_ed_file = "";
        this.manage_assistant_teacherid.mt_ed_file = filename;
        uploaded = true;
      }

      if (this.mt_transcript != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacherid.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "transcript.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_transcript);
        formData.append("filename", "../HREducation/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacherid.mt_transcript = "";
        this.manage_assistant_teacherid.mt_transcript = filename;
        uploaded = true;
      }

      if (this.mt_idcard_copy != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacherid.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "id.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_idcard_copy);
        formData.append("filename", "../HRIDcard/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacherid.mt_idcard_copy = "";
        this.manage_assistant_teacherid.mt_idcard_copy = filename;
        uploaded = true;
      }

      if (this.mt_change_name != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacherid.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "idchange.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_change_name);
        formData.append("filename", "../HRIDcard/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacherid.mt_change_name = "";
        this.manage_assistant_teacherid.mt_change_name = filename;
        uploaded = true;
      }

      let result_up = await this.$http.post(
        "manage_assistant_teacher.update.php",
        this.manage_assistant_teacherid
      );
      this.manage_assistant_teacherid;
      if (result_up.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.manage_assistant_teacherQueryAll();
    },

    async deletePic() {
      Swal.fire({
        title: "ยืนยันการลบ รูปถ่าย",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacherid.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" + this.manage_assistant_teacherid.mt_picprofile
          });

          this.manage_assistant_teacherid.mt_picprofile = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacherid
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.manage_assistant_teacherQueryAll();
        }
      });
    },

    async checkData(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.manage_assistant_teacherid = result.data;
      this.checkDataDialog = true;
    },
    async pdflicenseteachdialogOpen(pt_licence_file) {
      this.pt_licence_files = pt_licence_file;
      this.pdflicenseteachdialog_2 = true;
    },

    async pdfmt_transcriptdialogOpen(mt_transcript) {
      this.mt_transcript = mt_transcript;
      this.pdftranscriptdialog_2 = true;
    },

    async pdfmt_idcard_copydialogOpen(mt_idcard_copy) {
      this.mt_idcard_copy = mt_idcard_copy;
      this.pdfidcarddialog_2 = true;
    },

    async pdfmt_change_namedialogOpen(mt_change_name) {
      this.mt_change_name = mt_change_name;
      this.pdfidcardChangedialog_2 = true;
    },

    async branchQuery() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branchs = result.data;
    },

    async sweetAlertLoading() {
      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then(result => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      });
    },

    async manage_assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          report_selete: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async manage_assistant_teacherSearchQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          report_selete: "ok",
          duplicate: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async manage_assistant_teacherEdit(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.editmanage_assistant_teacherdialog = true;
    },
    async editmanage_assistant_teacherSubmit() {
      if (this.$refs.editmanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        if (this.editmanage_assistant_teacher.mt_status == "normal") {
          this.editmanage_assistant_teacher.mt_status == "";
        }
        let result = await this.$http.post(
          "manage_assistant_teacher.update.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editmanage_assistant_teacherdialog = false;
      }
    },
    async manage_assistant_teacherDelete(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.deletemanage_assistant_teacherdialog = true;
    },
    async deletemanage_assistant_teacherSubmit() {
      if (this.$refs.deletemanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "manage_assistant_teacher.delete.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "manage_assistant_teacher";
          this.data_syslog.table_log = "manage_assistant_teacher";
          this.data_syslog.detail_log =
            this.editmanage_assistant_teacher.id_position +
            " : " +
            this.editmanage_assistant_teacher.college_code +
            " : " +
            this.editmanage_assistant_teacher.case_vacancy;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletemanage_assistant_teacherdialog = false;
      }
    },
    row_classes(item) {
      /*  if (parseInt(item.mt_college_code) >= 1) {
        return "light-green";
      } else {
        return "amber";
      } */
    }
  },
  computed: {
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
    color() {
      return "blue-grey";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  },
  components: { Assistant_teacher_bar }
};
</script>

<style lang="scss" scoped></style>
